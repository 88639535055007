import { api } from "../../../api/axios-base";


export const fetchGetGroup = async (groupId) => {
  try {
    const { data } = await api.get(`/app-personas/grupo/${groupId}`);
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
